import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getAvailableMenuItems } from "api/requests/getAvailableMenuItems";
import { Category } from "custom-types/Category";
import { getCoordinates } from "redux/selectors/location";
import logError from "utils/logError";

import { CategoryImageMapType } from "components/ProductCategorySection/productCategoryImages";

/**
 * Fetches available product categories for strain, including image and corresponding /search/shop URL
 * @param {string} strainName
 * @returns {categories: [{title, imgSrc, targetUrl}, ...], isLoading: boolean}
 */
export const useStrainProductCategories = (
  strainName: string,
  categoryImageMap: CategoryImageMapType,
) => {
  const coordinates = useSelector(getCoordinates);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const categoriesOrder = Object.keys(categoryImageMap);
  const getAvailableStrainProductCategories = (strainName: string) => {
    getAvailableMenuItems(coordinates, [strainName])
      .then((availabilityResponse) => {
        if (availabilityResponse?.length) {
          const menuItemCategories = availabilityResponse[0].menuItemCategories
            .filter((category: Category) =>
              Object.keys(categoryImageMap).includes(category.name),
            )
            .sort(
              (a: Category, b: Category) =>
                categoriesOrder.indexOf(a.name) -
                categoriesOrder.indexOf(b.name),
            )
            .map((category: Category) => {
              const name: string = category.name;
              return {
                imgSrc: categoryImageMap[name],
                targetUrl: `/search/shop?shopCategory=strain&shopName=${encodeURIComponent(
                  strainName,
                )}&product_category=${category.name}`,
                title: category.name,
              };
            });
          setCategories(menuItemCategories);
        }
      })
      .catch(() => logError("Processing error in useStrainProductCategories"))
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () => getAvailableStrainProductCategories(strainName),
    [strainName],
  );

  return { categories, isLoading };
};
