import publicConfig from "config/public";

const {
  imgix: { publicUrl },
} = publicConfig;

export type CategoryImageMapType = {
  [index: string]: string;
};

/* eslint-disable sort-keys-fix/sort-keys-fix -- exception: order is important for display */
export const CATEGORY_IMAGE_MAP = Object.freeze({
  Flower: `${publicUrl}/web-home/product-categories/cannabis.jpg`,
  Cartridge: `${publicUrl}/web-home/product-categories/cartridges.jpg`,
  PreRoll: `${publicUrl}/web-home/product-categories/pre-rolls.jpg`,
  Concentrate: `${publicUrl}/web-home/product-categories/concentrates-yellow.jpg`,
  Edible: `${publicUrl}/web-home/product-categories/edibles-red.jpg`,
  Topicals: `${publicUrl}/web-home/product-categories/topicals.jpg`,
});
/* eslint-enable sort-keys-fix/sort-keys-fix -- exception: order is important for display */
