import { useEffect } from "react";

import { Category } from "custom-types/Category";
import { useStrainProductCategories } from "hooks/useStrainProductCategories";

import ProductCategoryCard from "components/botanic/ProductCategoryCard";
import ProductCategoryCardPlaceholder from "components/botanic/ProductCategoryCard/ProductCategoryCardPlaceholder";
import TrackImpression from "components/TrackImpression";

import { CATEGORY_IMAGE_MAP } from "./productCategoryImages";

type ProductCategorySectionProps = {
  onClick?: (category: string) => void;
  setShowSection?: (showSection: boolean) => void;
  strainName: string;
  trackImpressionCategory?: string;
  trackImpressionLabelPrefix?: string;
};

const ProductCategorySection: React.FC<ProductCategorySectionProps> = ({
  onClick = () => null,
  setShowSection = () => null,
  strainName,
  trackImpressionCategory,
  trackImpressionLabelPrefix,
  ...others
}) => {
  const { categories, isLoading } = useStrainProductCategories(
    strainName,
    CATEGORY_IMAGE_MAP,
  );

  const hasCategories = !!categories.length;

  // in place to hide entire parent container if this section,
  // the last section to be loaded in the parent container,
  // does not return results
  useEffect(() => {
    if (!isLoading && !hasCategories) {
      setShowSection(false);
    }
  }, [isLoading, categories.length]);

  const showSection = isLoading || hasCategories;

  return showSection ? (
    <div className="container" {...others}>
      <h2 className="md:text-center text-md mb-xs">
        Ready to try this strain?
      </h2>
      <p className="mb-md md:text-center">
        Shop {strainName} products near you
      </p>
      <div className="flex items-center md:justify-center overflow-auto lg:overflow-hidden mt-xl mr-none md:mr-auto">
        {isLoading ? (
          <>
            {Object.keys(CATEGORY_IMAGE_MAP).map((_, i) => (
              <div
                className="mr-lg"
                style={{ minWidth: 80, width: 80 }}
                key={i}
              >
                <ProductCategoryCardPlaceholder />
              </div>
            ))}
          </>
        ) : (
          categories.map((category: Category, i) => (
            <div
              className="mr-lg"
              style={{ minWidth: 80, width: 80 }}
              key={`${i}-${category.title}`}
            >
              <TrackImpression
                category={trackImpressionCategory}
                label={`${
                  trackImpressionLabelPrefix
                    ? trackImpressionLabelPrefix + " - "
                    : ""
                }${category.title}`} // labels reference https://leafly.atlassian.net/browse/AE-1045
                key={`${i + 1}-${category.title}`}
              >
                <ProductCategoryCard
                  className="text-xs"
                  image={category.imgSrc}
                  title={category.title}
                  titleSize="xs"
                  titleClassName="underline"
                  url={category.targetUrl}
                  onClick={() => onClick(category.title)}
                />
              </TrackImpression>
            </div>
          ))
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ProductCategorySection;
